.p-32 {
  padding: 32px;
}

.p-0 {
  padding: 0px;
}

.p-16 {
  padding: 16px !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}
.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-16 {
  margin-top: 16px;
}

.d-flex {
  display: flex;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.justify-centent-center {
  justify-content: center;
}

.h-250 {
  height: 250px;
  overflow: auto;
}

.justify-content-evenly {
  justify-content: evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.align-items-center {
  align-items: center;
}

.negative {
  color: red;
}

.positive {
  color: green;
}

.mainContainer {
  display: flex;
  height: 100vh;
  width: 100vw;
  position: absolute;
  /* background-image: radial-gradient(rgb(250, 250, 250), rgb(233, 233, 233)); */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  overflow: hidden;
}

[data-popper-placement="right"]:after {
  content: ' ';
  width: 0px;
  height: 0px;
  left: 2px;
}

.VerticalNavbar {
  border: 0px;
  margin: 0px;
  max-height: calc(100vh - 0px);
  min-height: auto;
}

.VerticalNavbar .eds-vertical-navbar__strip {
  padding: 0px;
}

.VerticalNavbar .eds-vertical-navbar__strip ul {
  overflow: auto;
  padding: 0px;
}

.appContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  /* background-image: linear-gradient(rgb(245, 245, 245), rgb(243, 243, 243), rgb(220, 220, 220)); */
}

.AppHeader {
  /* background-image: linear-gradient(rgb(79, 79, 79), rgb(167, 167, 167)); */
}

.toggleButton:hover {
  /* background-image: linear-gradient(rgb(255, 255, 255), rgb(235, 235, 235)); */
}

.buttonSearch:hover {
  /* background-image: linear-gradient(rgb(37, 38, 42), rgb(80, 80, 85)); */
}

.buttonReset:hover {
  /* background-image: linear-gradient(rgb(37, 38, 42), rgb(80, 80, 85)); */
}

h1.eds-app-header__name,
.eds-app-header .app-header-region span,
.eds-app-header h2
{
  font-weight: bold !important;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.poNumberLevel1:hover {
  cursor: pointer;
}

.poNo:hover {
  /* background-image: linear-gradient(rgb(201, 198, 197), rgb(209, 209, 209)); */
}

.order-by-type-tooltip {
  top: 2px;
  right: 10px;
}

.metricsDashboardLevelZeroRow:hover {
  cursor: pointer;
  background-image: linear-gradient(rgb(211, 208, 207), rgb(203, 196, 196));

}

/* .eds-table .eds-table-heading {
  min-width: auto;
} */

.textfield-multiple {
  position: relative;
  z-index: 10;
}

.textfield-multiple .add-btn {
  position: absolute;
  right: 10px;
  top: 32px;
}

.textfield-values-multiple {
  position: absolute;
  background: #fff;
  width: 100%;
  padding: 2px 8px;
  max-height: 130px;
  overflow: auto;
  visibility: hidden;
}

.textfield-multiple:hover .textfield-values-multiple {
  visibility: visible;
}

.textfield-values-multiple li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.textfield-values-multiple li:last-child {
  border-bottom: 0px;
}

.textfield-values-multiple .remove-btn {
  margin-right: 14px;
}

.table-layout-level1 {
  position: relative;
}

.table-layout-level1 .static-table {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.table-layout-level1 .horizontal-scroll-table {
  overflow: auto;
  width: 100%;
}

.table-layout-level1 .horizontal-scroll-table table thead th:nth-child(1),
.table-layout-level1 .horizontal-scroll-table table tbody td:nth-child(1) {
  width: 80px;
}

.table-layout-level1 .horizontal-scroll-table table thead th:nth-child(2),
.table-layout-level1 .horizontal-scroll-table table tbody td:nth-child(2) {
  min-width: 130px;
}

.table-layout-level1 .horizontal-scroll-table table thead th:nth-child(3),
.table-layout-level1 .horizontal-scroll-table table tbody td:nth-child(3) {
  min-width: 170px;
  padding: 0px;
}

.table-layout-level1 table thead th {
  font-size: 100%;
}

.waiting {
  position: absolute;
  background: #ffffffcc;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 13;
  align-items: center;
}

.qualtrics-survey-container {
  position: fixed;
  top: 50%;
  right: 0px;
  height: 640px;
  width: 400px;
  background: #999;
  margin-top: -320px;
  -webkit-transition: right 0.5s ease-in-out;
  -moz-transition: right 0.5s ease-in-out;
  -o-transition: right 0.5s ease-in-out;
  transition: right 0.5s ease-in-out;
}

.qualtrics-survey-container.qualtrics-survey-container-hide{
  right: -400px;
}

.qualtrics-survey-container .qualtrics-survey-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
}

.qualtrics-survey-container .qualtrics-survey-button {
  transform: rotate(270deg);
  position: absolute;
  top: 50%;
  left: -105px;
  margin-top: -24px;
  border-radius: 2px;
}

.qualtrics-survey-container .qualtrics-survey-frame {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
}
.eds-grid .eds-card {
  border: 1px solid #ccc;
}
.datetime-picker {
  z-index: 20;
}
.datetime-picker input {
  text-align: left !important;
} 
.search-card .eds-grid {
  gap: 0px 16px;
}
.order-reprocess-search-card .eds-text-field {
  margin-top: 2px !important;
}
.order-reprocess-buttons {
  position: fixed;
  bottom: 0px;
  padding: 8px 81px;
  width: 100%;
  left: 0px;
  background: #fff;
  box-shadow: 0px -1px 3px #ccc;
}
.eds-side-panel__content>div{
  overflow: auto;
}